import { ProjectInterface } from '../reducers/types';
import { showcaseImages, showcaseVideos } from '../assets';


import prizesCategories from './prizes';
import events from './events'

const Projects: ProjectInterface[] = [
    {
        id: "1",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'SVM',
        secondLink: 'https://bioml-svm-svm.hf.space',
        info: 'Generate unified protein embedding across multiple protein modalities.',
        mainImage: showcaseImages['svm'],
        mainVideo: showcaseVideos['svm'],
        iframe: false,
        prizes: prizesCategories.filter(prizeCategory => ['1'].includes(prizeCategory.id)),
    },
    {
        id: "4",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'MISATO',
        secondLink: 'https://misato-dataset-qm-property-calculation.hf.space',
        info: 'Predicting protein adaptability using GNN and LLM models trained on the MISATO dataset',
        mainImage: showcaseImages['misato'],
        mainVideo: showcaseVideos['misato'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['2'].includes(prizeCategory.id)),
    },
    {
        id: "5",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Sequence Diffusion',
        secondLink: 'https://merle-protein-generator.hf.space',
        info: 'Sample new proteins from an enzyme family through diffusion models for proteins.',
        mainImage: showcaseImages['sequence-diffusion'],
        mainVideo: showcaseVideos['sequence-diffusion'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['3'].includes(prizeCategory.id)),
    },
    {
        id: "6",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Metrix',
        secondLink: 'https://dnastory.github.io/metrix/',
        info: 'Explore your personal DNA data in an accessible and private format.',
        mainImage: showcaseImages['metrix'],
        mainVideo: showcaseVideos['metrix'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['4'].includes(prizeCategory.id)),
    },
    {
        id: "7",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Suri',
        secondLink: 'https://huggingface.co/account18hackathon/Suri',
        info: 'Developing a new pipeline to make training large models more efficient.',
        mainImage: showcaseImages['suri'],
        mainVideo: showcaseVideos['suri'],
        iframe: false,
        prizes: prizesCategories.filter(prizeCategory => ['5'].includes(prizeCategory.id)),
    },
    {
        id: "2",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Life Language Model',
        codeLink: 'https://github.com/dimenwarper/gexpfoundation_hackathon',
        info: 'Fine-tune large language models for single cell biology on smaller datasets like DepMap.',
        mainImage: showcaseImages['life-language-model'],
        mainVideo: showcaseVideos['life-language-model'],
        iframe: false,
    },
    {
        id: "3",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Resurrection Squad',
        secondLink: 'https://stellular-kangaroo-fec209.netlify.app/',
        info: 'Resurrect old codebases by automatically creating working dev environments.',
        mainImage: showcaseImages['resurrection-squad'],
        mainVideo: showcaseVideos['resurrection-squad'],
        iframe: true,
    },

    //Evolved 2024

    {
        id: "29",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'EvoCapsid',
        secondLink: 'https://www.evocapsid.bio/',
        info: 'Gene delivery evolved — design custom gene delivery agents with an end-to-end ML workflow, from cargo selection to targeted delivery.',
        mainImage: showcaseImages['evo-capsid'],
        mainVideo: showcaseVideos['evo-capsid'],
        iframe: false,
        prizes: prizesCategories.filter(prizeCategory => ['6', '10', '14'].includes(prizeCategory.id)),
    },
    {
        id: "19",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'MSEffect',
        secondLink: 'https://mseffect.net/',
        info: 'Identify unknown molecular spectra by integrating multiple models for precise MS/MS predictions.',
        mainImage: showcaseImages['ms-effect'],
        mainVideo: showcaseVideos['ms-effect'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['7', '11'].includes(prizeCategory.id)),
    },
    {
        id: "15",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'GenPlasmid',
        codeLink: 'https://github.com/wconnell/genplasmid',
        secondLink: "https://behindbioml.substack.com/p/bio-x-ml-hackathon-our-3rd-place",
        info: 'Automate plasmid design to improve protein expression and streamline molecular biology workflows.',
        mainImage: showcaseImages['gen-plasmid'],
        mainVideo: showcaseVideos['gen-plasmid'],
        prizes: prizesCategories.filter(prizeCategory => ['8', '15'].includes(prizeCategory.id)),
        iframe: false,
    },
    {
        id: "28",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'BST',
        codeLink: 'https://github.com/TeamBST-BioxML',
        secondLink: 'http://54.183.134.98:8050/',
        info: 'A midbrain organoid platform derived from Parkinson’s patients, combined with a Foundation Model-based network analysis protocol.',
        mainImage: showcaseImages['bst'],
        mainVideo: showcaseVideos['bst'],
        iframe: false,
        prizes: prizesCategories.filter(prizeCategory => ['18'].includes(prizeCategory.id)),
    },
    {
        id: "16",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'GLIMPSE',
        codeLink: 'https://github.com/ayushnoori/glimpse',
        secondLink: 'https://glimpsefor.bio/',
        info: 'Answer complex biomedical questions with graph-powered AI, driving insights in precision medicine.',
        mainImage: showcaseImages['glimpse'],
        mainVideo: showcaseVideos['glimpse'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['17'].includes(prizeCategory.id)),
    },
    {
        id: "18",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Interprotability',
        codeLink: 'https://github.com/etowahadams/plm-interpretability',
        secondLink: 'https://interprot.com',
        info: 'Interpret key features in protein language models using sparse autoencoders to reveal biological functions.',
        mainImage: showcaseImages['interprotability'],
        mainVideo: showcaseVideos['interprotability'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['12'].includes(prizeCategory.id)),
    },
    {
        id: "20",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'MorphoLogic AI',
        codeLink: 'https://github.com/kishpish/Morphologic-AI/tree/main',
        secondLink: 'https://pathomics.ai/morphologic-ai',
        info: 'Analyze cellular morphology with AI to understand disease effects, drug responses, and transcriptional changes.',
        mainImage: showcaseImages['morpho-logic'],
        mainVideo: showcaseVideos['morpho-logic'],
        iframe: true,
        prizes: prizesCategories.filter(prizeCategory => ['13'].includes(prizeCategory.id)),
    },
    {
        id: "24",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Silica',
        codeLink: 'https://github.com/hazemessamm/silica',
        secondLink: 'https://silica.my.canva.site/',
        info: 'Generate optimized enzymes and nanobodies using computational pipelines for enhanced stability and binding.',
        mainImage: showcaseImages['silica'],
        mainVideo: showcaseVideos['silica'],
        iframe: false,
        prizes: prizesCategories.filter(prizeCategory => ['14'].includes(prizeCategory.id)),
    },


    {
        id: "8",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'The Alchemists',
        codeLink: 'https://github.com/the-alchemists-formerly-5/scratch_repository',
        secondLink: 'https://team5-hackathon-bio-frontend.vercel.app/',
        info: 'Predict how molecules fragment in mass spectrometry using ChemBERTa, advancing chemical analysis for drug discovery and beyond.',
        mainImage: showcaseImages['the-alchemists'],
        mainVideo: showcaseVideos['the-alchemists'],
        iframe: true,
    },
    {
        id: "9",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Automated Data Scientist',
        codeLink: 'https://github.com/sundai-club/automated_data_scientist',
        secondLink: 'http://geneease.sundai.club/',
        info: 'GeneEase simplifies genomic exploration with an AI platform that manages data, writes code, and interprets results through chatbots and mind maps.',
        mainImage: showcaseImages['automated-data-scientist'],
        mainVideo: showcaseVideos['automated-data-scientist'],
        iframe: false,
    },
    {
        id: "10",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Blurry',
        codeLink: 'https://github.com/ManvithaPonnapati/NeuroForge',
        info: 'Achieve high-accuracy predictions of AAV capsid viability with adapter-guided protein models using just ~4,000 parameters.',
        mainImage: showcaseImages['blurry'],
        mainVideo: showcaseVideos['blurry'],
        iframe: false,

    },
    {
        id: "11",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'CHOformer',
        codeLink: 'https://github.com/RJain12/choformer',
        secondLink: 'https://www.choformer.com/',
        info: 'Optimize codon sequences for CHO cells to improve protein production and accelerate pharmaceutical manufacturing.',
        mainImage: showcaseImages['choformer'],
        mainVideo: showcaseVideos['choformer'],
        iframe: true,
    },
    {
        id: "12",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'MISATO',
        secondLink: 'https://huggingface.co/spaces/MISATO-dataset/esm3-conformational-sampling',
        info: 'Simulate centuries of protein evolution to explore the flexibility and conformational landscapes of proteins.',
        mainImage: showcaseImages['misato2'],
        mainVideo: showcaseVideos['misato2'],
        iframe: false,
    }
    ,
    {
        id: "13",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'De-MS-tifying Dark Mols',
        codeLink: 'https://github.com/ChrisTho23/dMSdMol',
        info: 'Predict mass spectrometry spectra from molecular structures, expanding the reach of molecular analysis beyond existing libraries.',
        mainImage: showcaseImages['dark-mols'],
        mainVideo: showcaseVideos['dark-mols'],
        iframe: false,
    },



    {
        id: "21",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Naturals',
        codeLink: 'https://github.com/davidkubanek/biohack',
        info: 'Classify molecular similarity with AI-powered tools to reveal hidden chemical relationships.',
        mainImage: showcaseImages['naturals'],
        mainVideo: showcaseVideos['naturals'],
        iframe: false,
    },
    {
        id: "22",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'PhageBook',
        codeLink: 'https://github.com/havu73/hackathonBio',
        secondLink: 'https://open.substack.com/pub/phagebookwins/p/phagebook-finding-the-right-viruses?r=4my8zq&utm_campaign=post&utm_medium=web&showWelcomeOnShare=true',
        info: 'Predict phage-bacteria interactions to develop precise and effective phage therapies for antibiotic resistance.',
        mainImage: showcaseImages['phage-book'],
        mainVideo: showcaseVideos['phage-book'],
        iframe: false,
    },
    {
        id: "23",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'PhenoSeq',
        codeLink: 'https://github.com/gdolsten/seq-to-pheno',
        secondLink: 'https://huggingface.co/seq-to-pheno',
        info: 'Map protein networks to phenotypic outcomes, advancing disease research and therapeutic development.',
        mainImage: showcaseImages['pheno-seq'],
        mainVideo: showcaseVideos['pheno-seq'],
        iframe: false,
    },

    {
        id: "25",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'The Foldables',
        codeLink: 'https://github.com/the-foldables/autogen-prototype',
        info: 'Empower scientists with an AI agent that integrates simulations, experiments, and models through natural language.',
        mainImage: showcaseImages['foldables'],
        mainVideo: showcaseVideos['foldables'],
        iframe: false,
    },
    {
        id: "26",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'TRegulators',
        codeLink: 'https://github.com/trevorpfiz/BioxML-allergen-epitopes',
        secondLink: 'https://biox-ml-allergen-epitopes.vercel.app/',
        info: 'Identify peptides that promote immune tolerance while avoiding allergic responses, supporting safer immunotherapies.',
        mainImage: showcaseImages['t-regulators'],
        mainVideo: showcaseVideos['t-regulators'],
        iframe: true,
    },
    {
        id: "27",
        event: events.filter(event => ['evolved-2024'].includes(event.id))[0],
        title: 'Bos-SF',
        codeLink: 'https://github.com/ManvithaPonnapati/NeuroForge',
        secondLink: 'https://blyncha.github.io/evolved24hack/',
        info: 'Design proteins with cellular context to optimize expression in mouse brain cells, enabling cell-type-specific therapies.',
        mainImage: showcaseImages['bossf'],
        mainVideo: showcaseVideos['bossf'],
        iframe: true,
    },


];


export default Projects;