import React from 'react';
import { ProjectInterface } from '../../reducers/types';
import { useSelector } from 'react-redux';
import { projectsSelector } from '../../selectors/selectors';
import "./Project.css";

import { useParams } from 'react-router-dom';
const Project: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const projects = useSelector(projectsSelector);
    const project = projects.find((project: ProjectInterface) => project.id === projectId);

    return (<div className='container'>


        <div className="row project-wrapper">

            {project?.mainVideo ?
                <div className={project?.iframe ? "col-lg-6" : 'col-md-8'}>
                    <video id={'video_' + project?.id} controls src={project?.mainVideo + '#t=2'} />
                </div>
                : ''}

            {project?.iframe ?
                <div className="col-lg-6">
                    <iframe
                        src={project?.secondLink}
                        width="850"
                        height="450"
                        frameBorder="0"
                    ></iframe>


                </div> : ''}

        </div>
        <div className="title-section" style={{ marginTop: '20px' }}>
            <h1>{project && project.title}</h1>
        </div>
        <p>
            {project && project.info}
        </p>
        {project?.codeLink && (
            <a
                href={project.codeLink}
                target="_blank"
                className="button"
                style={{ marginTop: '15px' }}
            >
                Code
            </a>
        )}
        {project?.secondLink && (
            <a
                href={project.secondLink}
                target="_blank"
                className="button"
                style={{
                    marginTop: '15px',
                    marginLeft: project?.codeLink ? '15px' : '0px',
                }}
            >
                Project Page
            </a>
        )}


        {project && project.prizes && project.prizes.length > 0 ? (
            <div className='project-prizes' style={{ marginTop: '15px' }}>
                {project.prizes.map((prize, index) => (
                    <div key={index} className='project-prize'>
                        Winner of {prize.name} {prize.emoji} @ {project.event.title}
                    </div>
                ))}
            </div>
        ) : ''}
        <div className='footer'></div>
    </div>);
};

export default Project;