import React from "react";
import "./TempHomePage.css";
import logo from "../../assets/main/evolved25_logo_white.png";
import Luxlogo from "../../assets/main/lux-white.png";
import Nebiuslogo from "../../assets/main/Nebius_logo_white.png" // Ensure this path is correct
import NVIDIAlogo from "../../assets/main/NVIDIA_logo_white.png" // Ensure this path is correct

const TempHomePage: React.FC = () => {
    return (
      <div className="temp-home-container">
        <div className="background-image"></div>
        <div className="content">
          <img src={logo} alt="Hackathon Logo" className="logo" />
          <p className="responsive-text">Evolved 2025 is a global hackathon initiative to accelerate AI breakthroughs in healthcare and life sciences. The program includes regional in-person hackathons, a global virtual hackathon, and a grand challenge showcasing top AI-driven biotech and clinical development innovations. Evolved 2025 is run by Nucleate and enabled by Lux Capital, Nebius, and NVIDIA. Register interest and stay tuned for updates below.</p>
          <div className="buttons">
            <a href="https://airtable.com/appCgWMvQescnEfHv/pagDAABEU80ivJbg0/form" target="_blank" rel="noopener noreferrer">
              <button className="cta-button">Stay Informed</button>
            </a>
            <a href="/2024">
              <button className="cta-button secondary">Previous Years</button>
            </a>
          </div>
          <div className="divider"></div>
          <div className="partner-logos">
            <div className="partner-logo-container">
              <img src={NVIDIAlogo} alt="Partner Logo 1" className="partner-logo" />
            </div>
            <div className="partner-logo-container">
              <img src={Luxlogo} alt="Partner Logo 1" className="partner-logo" />
            </div>
            <div className="partner-logo-container">
              <img src={Nebiuslogo} alt="Partner Logo 2" className="partner-logo" />
            </div>
          </div>
        </div>
      </div>
    );
  };

export default TempHomePage;
