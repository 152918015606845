// import React, { useState, CSSProperties } from 'react';
// import { ProjectInterface, EventInterface } from '../../reducers/types';
// import { useSelector } from 'react-redux';
// import { projectsSelector, eventsSelector } from '../../selectors/selectors';
// import { useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap-grid.css';

// import codeIcon from '../assets/icons/code-icon.svg';
// import videoIcon from '../assets/icons/video-icon.svg';
// import "./Projects.css";
// import { Helmet } from 'react-helmet';
// import generalShare from '../../assets/main/general-share.webp';

// const ProjectsPage: React.FC = () => {
//     const projects = useSelector(projectsSelector);
//     const events = useSelector(eventsSelector);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedEvent, setSelectedEvent] = useState('Evolved - 2024');
//     const [filteredProjects, setFilteredProjects] = useState(projects);
//     const navigate = useNavigate();


//     const search = () => {
//         setFilteredProjects(projects.filter((project: ProjectInterface) => {
//             const eventTitle = project.event.title;
//             const searchMatch = project.title.toLowerCase().includes(searchTerm.toLowerCase()) || project.info.toLowerCase().includes(searchTerm.toLowerCase());
//             const eventMatch = selectedEvent === 'all' || eventTitle === selectedEvent;
//             return searchMatch && eventMatch;
//         }));
//     }

//     const eventTitles = Array.from(new Set(projects.map(project => project.event.title)));
//     const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
//     const title = 'Evolved - Showcase'

//     return (
//         <div>
//             <Helmet htmlAttributes={{ lang: 'en' }}>
//                 <title>{title}</title>
//                 {/* <link rel="icon" href={favicon} /> */}
//                 <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
//                 <meta property="og:title" content={title} />
//                 <meta property="og:type" content="article" />
//                 <meta property="og:image"
//                     content={generalShare} />
//                 <meta property="og:url" content="https://hackathon.bio" />
//                 <meta name="twitter:card" content={metaDescription} />
//                 <meta property="og:description"
//                     content={metaDescription} />
//                 <meta property="og:site_name" content={title} />
//                 <meta name="twitter:image:alt" content={title} />
//                 <meta name="twitter:site" content="@hackathon.bio" />
//                 <meta http-equiv="x-ua-compatible" content="ie=edge" />

//                 <meta name="viewport" content="width=device-width, initial-scale=1" />
//                 <meta name="description"
//                     content={metaDescription} />
//                 <link rel="stylesheet"
//                     href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
//             </Helmet>

//             <div className='filter-controls'>
//                 <h1>Find a Project</h1>
//                 <div className='filter-title'>Check out the projects created at past events</div>

//                 <input
//                     type="text"
//                     placeholder="Search projects..."
//                     // value={searchTerm}
//                     onChange={(e) => {
//                         setSearchTerm(e.target.value);
//                         search();
//                     }}
//                 />
//                 <select className="form-select" style={{ marginLeft: '20px', width: '150px', display: 'inline' }} value={selectedEvent} onChange={(e) => {
//                     setSelectedEvent(e.target.value);
//                     search();
//                 }
//                 }>
//                     <option value="all">All Events</option>
//                     {eventTitles.map((title, index) => (
//                         <option key={index} value={title}>{title}</option>
//                     ))}
//                 </select>
//             </div>

//             <div className="container">
//                 <div className="row">
//                     {filteredProjects.map((project: ProjectInterface) => (
//                         <div key={project.id} className="col-md-4 fade-in">
//                             <div className="overview-card project-card" onClick={() => navigate(`/project/${project.id}`)}>

//                                 <img src={project.mainImage} alt={project.title} />

//                                 <h2>{project.title}</h2>
//                                 <p>{project.info}</p>
//                                 <div className='event-badge'>
//                                     {project.event.title}
//                                 </div>
//                                 {(project.prize && project.prize?.emoji) ?
//                                     <div className='event-badge' style={{
//                                         marginLeft: '10px',
//                                         // background: 'var(--evolved-earth)',
//                                         // color: 'white'
//                                         background: 'unset',
//                                         border: '1px solid black',
//                                         paddingLeft: '10px',
//                                         paddingRight: '10px',
//                                     }}>
//                                         {project.prize.emoji}
//                                     </div> : ''}
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='footer'></div>
//         </div>
//     );
// };

// export default ProjectsPage;

import React, { useState, CSSProperties, useEffect } from 'react';
import { ProjectInterface, EventInterface } from '../../reducers/types';
import { useSelector } from 'react-redux';
import { projectsSelector, eventsSelector } from '../../selectors/selectors';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap-grid.css';

import codeIcon from '../assets/icons/code-icon.svg';
import videoIcon from '../assets/icons/video-icon.svg';
import "./Projects.css";
import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';

const ProjectsPage: React.FC = () => {
    const projects = useSelector(projectsSelector);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEvent, setSelectedEvent] = useState('Evolved - 2024'); // Default to 'Evolved - 2024'
    const [filteredProjects, setFilteredProjects] = useState<ProjectInterface[]>([]);
    const navigate = useNavigate();

    const eventTitles = Array.from(new Set(projects.map(project => project.event.title)));
    const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`;
    const title = 'Evolved - Showcase';

    // Filter logic extracted into a reusable function
    const filterProjects = (search: string, event: string) => {
        const filtered = projects.filter((project: ProjectInterface) => {
            const eventTitle = project.event.title;
            const searchMatch =
                project.title.toLowerCase().includes(search.toLowerCase()) ||
                project.info.toLowerCase().includes(search.toLowerCase());
            const eventMatch = event === 'all' || eventTitle === event;
            return searchMatch && eventMatch;
        });
        setFilteredProjects(filtered);
    };

    // Run filter on page load to apply the default selectedEvent
    useEffect(() => {
        filterProjects(searchTerm, selectedEvent);
    }, [projects, selectedEvent]);

    return (
        <div>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>{title}</title>
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={generalShare} />
                <meta property="og:url" content="https://hackathon.bio" />
                <meta name="twitter:card" content={metaDescription} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:site_name" content={title} />
                <meta name="twitter:image:alt" content={title} />
                <meta name="twitter:site" content="@hackathon.bio" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={metaDescription} />
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
                />
            </Helmet>

            <div className='filter-controls'>
                <h1>Find a Project</h1>
                <div className='filter-title'>Check out the projects created at past events</div>

                <input
                    type="text"
                    placeholder="Search projects..."
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        filterProjects(e.target.value, selectedEvent);
                    }}
                />
                <select
                    className="form-select"
                    style={{ marginLeft: '20px', width: '200px', display: 'inline' }}
                    value={selectedEvent}
                    onChange={(e) => {
                        setSelectedEvent(e.target.value);
                        filterProjects(searchTerm, e.target.value);
                    }}
                >
                    <option value="all">All Events</option>
                    {eventTitles.map((title, index) => (
                        <option key={index} value={title}>{title}</option>
                    ))}
                </select>
            </div>

            <div className="container">
                <div className="row">
                    {filteredProjects.map((project: ProjectInterface) => (
                        <div key={project.id} className="col-md-4 fade-in">
                            <div
                                className="overview-card project-card"
                                onClick={() => navigate(`/project/${project.id}`)}
                            >
                                <img src={project.mainImage} alt={project.title} />
                                <h2>{project.title}</h2>
                                <p>{project.info}</p>
                                <div className='event-badge'>{project.event.title}</div>
                                {/* {project.prize?.emoji && (
                                    <div
                                        className='event-badge'
                                        style={{
                                            marginLeft: '10px',
                                            background: 'unset',
                                            border: '1px solid black',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        {project.prize.emoji}
                                    </div>
                                )} */}
                                {project.prizes && project.prizes.length > 0 && (
                                    // <div style={{ display: 'flex', gap: '10px' }}>
                                    <>
                                        {project.prizes.map((prize, index) => (
                                            prize.emoji && (
                                                <div
                                                    key={index}
                                                    className='event-badge'
                                                    style={{
                                                        marginLeft: '10px',
                                                        background: 'unset',
                                                        border: '1px solid black',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                    }}
                                                >
                                                    {prize.emoji}
                                                </div>
                                            )
                                        ))}
                                    </>
                                    // </div>
                                )}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='footer'></div>
        </div>
    );
};

export default ProjectsPage;
