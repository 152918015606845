import { ResourceInterface } from '../reducers/types';
import companies from './companies';
// import { assetsImages } from '../assets';

const resources: ResourceInterface[] = [
    {
        id: 0,
        title: 'GPT-4 Plugins',
        url: 'https://www.openai.com/',
        description: `Build plugins with GPT-4 to provide the model with information from other services.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'OpenAI'),
        active: false,
    },
    {
        id: 1,
        title: 'Chroma',
        url: 'https://www.trychroma.com/',
        description: `Open-source AI-first embeddings store. Easily add state, memory, and skills to your AI application.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'Chroma'),
        active: false,
    },
    {
        id: 2,
        title: 'Lambda Labs',
        url: 'https://www.lambdalabs.com/',
        description: `Train, finetune, and run inference via API using Lambda's GPU Cloud. 2 GPU's will be provided per team by organizers.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'Lambda Labs'),
        active: false,
    },
    {
        id: 3,
        title: 'GPT-4 API',
        url: 'https://www.openai.com/',
        description: `Use the GPT-4 API for any task that involves understanding or generating natural language, code, or images.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'OpenAI'),
        active: false,
    },
    {
        id: 4,
        title: 'Vercel',
        url: 'https://www.vercel.com/',
        description: `Develop, preview, and ship delightful user experiences with frontend templates.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'Vercel'),
        active: false,
    },
    {
        id: 5,
        title: 'LatchBio',
        url: 'https://www.latch.bio/',
        description: `The cloud for biology. Each team will have $2,000 in compute credits. Make data analysis faster, more accessible, and scalable with self-serve workflows.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'LatchBio'),
        active: true,
    },
    {
        id: 6,
        title: 'Hugging Face',
        url: 'https://www.huggingface.co/',
        description: `Hugging Face is proud to sponsor GPU grants on Hugging Face Spaces; more information can be found on their website.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'Hugging Face'),
        active: false,
    },
    {
        id: 7,
        title: 'AWS',
        url: 'https://www.aws.amazon.com/',
        description: `On-demand cloud computing and APIs. Each team will have $2,000 in compute credits. Run workloads and train deep learning models.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'AWS'),
        active: false,
    },
    {
        id: 8,
        title: 'Anthropic',
        url: 'https://www.anthropic.com/',
        description: `API for early access to Claude to aid with natural language tasks such as summarization, coding, Q&A and more! See documentation for more details.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'Anthropic'),
        active: false,
    },
    {
        id: 9,
        title: 'RunPod',
        url: 'https://www.runpod.io/',
        description: `A globally distributed GPU cloud. Each member of a team will have $100 in compute credits. Develop, train, and scale AI models.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'RunPod'),
        active: true,
    },
    {
        id: 10,
        title: 'Nomic AI',
        url: 'https://www.nomic.ai/',
        description: `Use Atlas as a Data IDE to explore, label, search and share massive datasets in your web browser. Nomic will provide credits for up to 3M points per map.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'Nomic'),
        active: false,
    },
    {
        id: 11,
        title: 'Modal',
        url: 'https://modal.com/',
        description: `The serverless platform for AI teams. Each team member will have $500 in compute credits. Run generative AI models, large-scale batch jobs, and job queues.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'Modal'),
        active: true,
    }, {
        id: 12,
        title: 'AWS',
        url: 'https://www.aws.amazon.com/',
        description: `On-demand cloud computing and APIs. Each team will have $5,000 in compute credits. Run workloads and train deep learning models.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'AWS'),
        active: true,
    }, {
        id: 13,
        title: 'Together AI',
        url: 'https://www.together.ai/',
        description: `An AI acceleration cloud. Each member of a team will have $500 in compute credits. Perform large training and fine-tuning of models.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'Together AI'),
        active: true,
    },
    {
        id: 14,
        title: 'DigitalOcean',
        url: 'https://www.digitalocean.com/',
        description: `Cloud infrastructure for developers. Each team will have $10,000 in credits. Build and scale AI models.`,
        type: 'Compute',
        sponsor: companies.find(sponsor => sponsor.name === 'DigitalOcean'),
        active: true,
    },
    {
        id: 15,
        title: 'OpenAI',
        url: 'https://openai.com/',
        description: `AI research and deployment. Each member of a team will have $100 in credits. Build and scale AI experiences.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'OpenAI'),
        active: true,
    },
    {
        id: 16,
        title: 'NVIDIA',
        url: 'https://ai.nvidia.com/',
        description: `Explore the latest community-built AI models with APIs optimized and accelerated by NVIDIA, then deploy anywhere with NVIDIA NIM™️ inference microservices. Apply with your company email address and get access to 5k free tokens. Startups formed will receive entry into Inception program for startups.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'NVIDIA'),
        active: true,
    },
    {
        id: 17,
        title: 'EvolutionaryScale',
        url: 'https://www.evolutionaryscale.ai/',
        description: `A frontier AI research lab for biology. Each team will receive exclusive early beta API access to 1.4B, 7B, and 98B ESM3 models via ES Forge, in addition to 10,000 daily credits. Simultaneously reason over the sequence, structure and function of proteins.`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'EvolutionaryScale'),
        active: true,
    }, {
        id: 18,
        title: 'Ginkgo',
        url: 'https://www.ginkgobioworks.com/',
        description: `A horizontal platform for cell programming. Each team will have 50k in inference calls for use of ESM-650M and AA0-650M (Ginkgo's version, retrained on an expanded metagenomic database).`,
        type: 'API',
        sponsor: companies.find(sponsor => sponsor.name === 'Gingko Bioworks'),
        active: true,
    }]

export default resources;