import { FaqInterface } from '../reducers/types';
const faq: FaqInterface[] = [
    {
        id: "1",
        q: 'Will it take place online or offline?',
        a: "The event will be virtual, on discord and Zoom with an optional in-person final hack weekend taking place on June 3rd-4th in the LatchBio office in SF. ",
    }, {
        id: "2",
        q: 'How do I sign up?',
        a: `1. Join the Discord using the "SIGN UP" button.

        2. Use the Discord to form teams, brainstorm ideas and explore datasets and models for your project idea!
        
        3. Submit an official Project Proposal with your team BEFORE May 26 2023 (PT), using this form: (https://s1nas4re5py.typeform.com/to/WqZwW2bK) 
        `,
    }, {
        id: "3",
        q: 'What resources will be provided?',
        a: `GPU's provided to fine tune and train models:

        - 48 A10's by Lambda Labs
        - $2000 AWS credits per team 
        - $100 credits on RunPod per team member
        - GPU grants on HuggingFace avaliable for Demo's in Spaces
        
        API's & Plug In's 
        - Access Keys to OpenAI API + Plug Ins
        - Access Keys to Antropic API
        
        & Other Visualisation and Training tools
        - NomicAI maps of embeddings 
        - Chroma's vector store`,
    }, {
        id: "4",
        q: 'How will teams be set up? ',
        a: `- 10-20 teams will be selected to participate according to our selection criteria. 

        - To be considered, each proposal needs a minimum of 5 people per team - use the discord as a way to explore datasets, propose projects + rally members to join forces.🧪 🧑‍🔬
        
        - Once accepted, we'll give each team / project lead access to all the GPU’s credits, API & other resources provided by our sponsors (OpenAI, Antropic, LatchBio, Lambda Labs, etc)
        
        - You'll be able to join one of the selected teams after it has been formed, if your proposal was not accepted.`,
    }, {
        id: "5",
        q: 'What are the requirements for the project proposal?',
        a: `- 1 'Project Lead' per team, only 1 proposal per team
        
        - Min 5 people per team, 15 max
        
        - Projects will be accepted according to the following criteria, in order of relevance:`,
    }, {
        id: "6",
        q: 'What is the selection criteria for Final Projects?',
        a: `⚕️  Impact of project + distribution: what problem are you solving, who is your end user, how do you plan to get this to them?

        🧠 Novelty of idea
        
        ⏳  Feasibility of resources/time `,
    }, {
        "id": "7",
        "q": "Who can participate?",
        "a": "Our hackathon is open to anyone around the world - you don’t need to be affiliated with any university or institute. We generally welcome anyone who is excited to build in Bio x AI."
    },
    {
        "id": "8",
        "q": "When and where is the hackathon?",
        "a": "The top 10-20 teams who submit a proposal will be selected to participate in our hackathon, which will take place virtually over 10 days (Oct 10-20). Our live pitch event in front of judges will also be virtual."
        // The only exception is our IRL event, where you can mix & mingle with other teams & experts in the Bio x AI space.
    },
    {
        "id": "9",
        "q": "I don’t yet have a team, or my team is not yet 5 people. What can we do?",
        "a": "Click the Join Discord button above. We’ve created #project-recruitment for interested hackers to intro themselves & find teammates. You’re among exceptional company - past participants have included startup operators and engineers from growing Series A and B companies, former CEOs and founders exploring their next ventures, and PhD candidates and PostDocs from institutions like MIT, Stanford, Oxford, and Cambridge."
    },
    {
        "id": "10",
        "q": "Do I need to have an idea to pitch?",
        "a": "Not to join the Discord. There, you can assemble a team and brainstorm together before submitting a project proposal by Sept 28. We encourage teams to also take risks w/ their ideas and tackle challenging/unsolved problems! See Assets for inspiration."
    },
    {
        "id": "11",
        "q": "How/when do teams get access to tools, credits, etc.?",
        "a": "This will only be available to the top 10-20 teams who have submitted a proposal and are selected to participate. Finalists will be notified on Oct 2 and invited to a private Discord channel, where we will send you access codes directly."
    },
    {
        "id": "12",
        "q": "What happens to my proposal or completed project after the event?",
        "a": "It is up to you and your team. We would be glad to point you towards additional resources  if you would like to receive more feedback and explore building the project out further after the hackathon."
    }]


export default faq;