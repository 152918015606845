import React, { useState, useEffect } from 'react';
import { EventInterface, CompanyInterface, PrizeCategoryInterface, PrizeInterface } from '../../reducers/types';
import { useSelector } from 'react-redux';
import { eventsSelector } from '../../selectors/selectors';
import "./Event.css";
import FaqList from './Faq'
import PhotoGrid from './PhotoGrid';
import Timeline from './Timeline';
import EventsTable from './Schedule';
import { useParams } from 'react-router-dom';
import AssetsSnippet from '../Assets/AssetsSnippet';
import ResourcesSnippet from '../Resources/ResourcesSnippet';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import logo from '../../assets/images/event-secondaries/lux-large2.2.webp';

const Event: React.FC = () => {
  //const { eventId } = useParams<{ eventId: string }>();

  const eventId = 'evolved-2024';

  const events = useSelector(eventsSelector);
  const event = events.find((event: EventInterface) => event.id === eventId);
  const [isSticky, setIsSticky] = useState(false);
  const [prizeSponsors, setPrizeSponsors] = useState<CompanyInterface[]>([]);
  const navigate = useNavigate();

  type SeenSponsors = { [key: string]: boolean };
  function extractUniqueSponsors(event: EventInterface) {
    if (!event || !event.prizeCategories) {
      return []; // Return an empty array if event or event.prizeCategories is undefined
    }

    var prizeSponsors_ = [];
    const seenSponsors: SeenSponsors = {};

    for (var i = 0; i < event.prizeCategories.length; i++) {
      var category = event.prizeCategories[i];
      if (!category.prizes) continue; // Skip categories without prizes

      for (var j = 0; j < category.prizes.length; j++) {
        var sponsor = category.prizes[j].sponsor;
        if (!seenSponsors[sponsor.name]) {
          seenSponsors[sponsor.name] = true; // Mark this sponsor as seen
          prizeSponsors_.push(sponsor); // Add to unique list
        }
      }
    }

    return setPrizeSponsors(prizeSponsors_);
  }

  useEffect(() => {

    if (!event) {
      return
    }
    extractUniqueSponsors(event);

    const handleScroll = () => {
      const currentScroll = window.scrollY;
      const primaryBarBottom = document.getElementById('event-image')?.getBoundingClientRect().top ?? 0;
      setIsSticky(currentScroll > primaryBarBottom);
      // console.log(currentScroll, primaryBarBottom)
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const formatTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const metaDescription = `Join Lux Capital, Evolutionary Scale & Enveda Bioscience in the second Bio x ML hackathon to drive forward the frontier of science. Build on top of the latest foundation models like ESM-3 (98B parameters) and proprietary datasets.`


  return (<div>

    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{event?.title}</title>
      {/* <link rel="icon" href={favicon} /> */}
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta property="og:title" content={event?.title} />
      <meta property="og:type" content="article" />
      <meta property="og:image"
        content={event?.mainImage} />
      <meta property="og:url" content="https://hackathon.bio" />
      <meta name="twitter:card" content={metaDescription} />
      <meta property="og:description"
        content={metaDescription} />
      <meta property="og:site_name" content={event?.title} />
      <meta name="twitter:image:alt" content={event?.title} />
      <meta name="twitter:site" content="@hackathon.bio" />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description"
        content={metaDescription} />
      <link rel="stylesheet"
        href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
    </Helmet>

    {/* <div className="title-section container"> */}
    <div className="title-section container">
      <h1>{event && event.title}</h1>
    </div>

    <div className={`title-section ${isSticky ? 'sticky' : ''} `}>

      <div className='secondary-navbar container'>
        <a href="#overview">Overview</a>
        {event?.schedule ? <a href="#schedule">Schedule</a> : ''}
        {event?.currentResources ? <a href="#resources">Resources</a> : ''}
        {/* {event?.speakers ? <a href="#speakers">Speakers</a> : ''} */}
        {event?.prizeCategories ? <a href="#prizes">Prizes</a> : ''}
        {/* {event?.judges ? <a href="#judges">Judges</a> : ''} */}
        {/* <a href="#partners">Partners</a> */}
        {event?.faqItems ? <a href="#faq">FAQ</a> : ''}
      </div>
    </div>





    <div id='event-image' className='event-image-wrapper'>
      {event && <img className='event-image' src={event.mainImage} alt={event.title} />}
    </div>


    <section id="overview" className="container" style={{ marginTop: '100px', marginBottom: '100px' }}>
      <div className="row d-flex align-items-center">
        <div className="col-md-6 event-image2 d-flex justify-content-center align-items-center">
          {/* {event && <img className='event-image' src={event?.secondaryImage} alt={event.title} />} */}
          {event && <img className='event-image' src={logo} alt={event.title} />}
        </div>
        <div className="col-md-6 event-highlight">
          <h2 >{event?.title}</h2>
          <p className="event-date">{event?.date}</p>
          <p>{event?.blurb}</p>
          <div className={event?.upcoming ? 'button' : 'button disabled'}
            onClick={() => event?.upcoming ? window.location.href = 'https://s1nas4re5py.typeform.com/to/dUNvqljy' : ''}>
            Apply Here
          </div>
          <div className='button2' style={{ marginLeft: '10px' }}
            onClick={() => window.location.href = 'https://discord.gg/kTEq6t99CK'}>
            Form Teams + Join Discord
          </div>

        </div>
      </div>
    </section>

    {/* {formatTextWithLineBreaks(event.gettingStarted)} */}

    <section className='container my-5'>
      <div className="row">
        <div className="col">

          {event?.gettingStarted ? (<>
            <h2 id="gettingStarted">How to Start</h2>
            {/* <div dangerouslySetInnerHTML={{ __html: event.gettingStarted }} /> */}
            <Timeline />
          </>) : ''
          }


        </div>
      </div>
    </section>

    <section className='container my-5'>
      <div className="row">
        <div className="col">

          {event?.schedule ? (<>
            <h2 id="schedule">Schedule</h2>

            <EventsTable eventsString={event.schedule} />
          </>) : ''
          }
        </div>
      </div>
    </section>

    <section className='container my-5'>
      <div className="row">
        <div className="col">

          {event?.currentResources ? (<>
            <h2 id="resources">Resources</h2>
            {/* <div onClick={() => navigate("/resources")} className='filter-title explore-more-datasets'>Explore more resources that you can build with here.</div> */}
            <ResourcesSnippet resources={event.currentResources} />
          </>) : ''
          }
        </div>
      </div>
    </section>



    <section id="speakers" className='container my-5'>
      <div className="row">
        <div className="col">
          {event?.speakers ? (<>
            <h2 >Speakers</h2>
            {/* <p>
              We're proud to feature an array of amazing speakers who are leaders in their fields, ready to share cutting-edge insights and inspire with their visionary talks throughout the hackathon!
            </p> */}

            <PhotoGrid people={event.speakers} />

          </>) : ''
          }
        </div>
      </div>
    </section>

    <section id="prizes" className='container my-5'>
      <div className="row">

        {event?.prizeCategories ? (<>
          <h2 >Prizes</h2>
          <p>{event?.prizesIntro}</p>

          {event.prizeCategories.map((prizeCategory: PrizeCategoryInterface) => (
            <div className="col-md-4">
              <h5>{prizeCategory.name} {prizeCategory.emoji}</h5>
              {prizeCategory.prizes.map((prize: PrizeInterface) => (<>

                {/*  <>
                {prize.name} <em>{prize.sponsor.name}</em>
                 <br></br>
                </> */}

                {prize.sponsor.logoWhite ? (
                  <>
                    <div className='prize-card' >
                      <img onClick={() => window.open(
                        prize.sponsor.link,
                        '_blank'
                      )} className="sponsor-logo" src={prize.sponsor.logoWhite}
                        alt="sponsor logo" style={{ maxHeight: '70px' }} />

                      <p>{prize.name.split(' ').slice(0, 3).join(' ')}</p>
                    </div>
                  </>) : ''
                }

              </>
              ))}
            </div >
          ))}

        </>) : ''
        }

      </div>

      {event?.prizeCategories ? (<>

        {event?.prizeCategories.map((prizeCategory: PrizeCategoryInterface) => (
          <>
            {prizeCategory.eligibilityCriteria ? (
              <div className="col">
                {prizeCategory.emoji} {prizeCategory.eligibilityCriteria}
              </div>
            ) : ''}
          </>
        ))}

      </>) : ''
      }

      {/* <div className="container" style={{ 'marginTop': '30px' }}>
        {event && prizeSponsors.length > 0 ? 'Made possible by' : ''}
        <div className="row justify-content-center" style={{ maxHeight: '150px' }}>
          {event && prizeSponsors.map((sponsor: CompanyInterface, index) => (
            sponsor.logo ? (
              <div className="col d-flex justify-content-center" key={index}>
                <img onClick={() => window.open(
                  sponsor.link,
                  '_blank'
                )} className="sponsor-logo" src={sponsor.logo} alt="sponsor logo" style={{ height: 'unset', opacity: '50%' }} />
              </div>
            ) : null
          ))}
        </div>
      </div> */}
    </section >

    <section className='container my-5'>
      <div className="row">
        <div className="col">

          {event?.currentAssets ? (<>
            <h2 id="assets">Assets</h2>

            {/* <div onClick={() => navigate("/assets")} className='filter-title explore-more-datasets'>Click here to explore expanded selection of valuable assets.</div>*/}

            <AssetsSnippet assets={event.currentAssets} />
          </>) : ''
          }
        </div>
      </div>
    </section>

    <section id="judges" className='container my-5'>
      <div className="row">
        <div className="col">
          {event?.judges ? (<>
            <h2>Judges</h2>
            <p>{event?.judgesIntro}</p>
            <PhotoGrid people={event.judges} />
          </>) : ''
          }
        </div>
      </div>
    </section>


    <section id="partners" className="my-5 partners-container">
      <div className="container">
        <div className="row justify-content-center">
          {event && event.sponsors.map((sponsor: CompanyInterface, index) => (
            <div className="col d-flex justify-content-center" key={index}>
              <img onClick={() => window.open(
                sponsor.link,
                '_blank'
              )} className="sponsor-logo" src={sponsor.logo} alt="sponsor logo" />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section id="faq" className='container my-5'>
      <div className="row">
        <div className="col">
          {(event && event?.faqItems) ?
            (<div>
              <h2 >FAQ</h2>
              <FaqList faqs={event?.faqItems} />
            </div>) : ''}
        </div>
      </div>
    </section>

  </div >

  );
};

export default Event;