import React, { useState, useRef } from 'react';
import './Challenge.css';
// import Header from '../Header/Header';
import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';

import challengeImage from '../../assets/images/challenges/enveda-2024.webp';
const ChallengePage: React.FC = () => {

    const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
    const title = 'Evolved - Challenge | Enveda'


    return (
        <>

            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>{title}</title>
                {/* <link rel="icon" href={favicon} /> */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image"
                    content={generalShare} />
                <meta property="og:url" content="https://hackathon.bio" />
                <meta name="twitter:card" content={metaDescription} />
                <meta property="og:description"
                    content={metaDescription} />
                <meta property="og:site_name" content={title} />
                <meta name="twitter:image:alt" content={title} />
                <meta name="twitter:site" content="@hackathon.bio" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description"
                    content={metaDescription} />
                <link rel="stylesheet"
                    href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
            </Helmet>



            <div className="container">
                {/* <div className="row">
                    {<Header />}
                </div> */}
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 event-image2 d-flex justify-content-center align-items-center">
                        <img className='event-image' src={challengeImage} alt='Enveda Logo' />
                    </div>
                    <div className="col-md-6 event-highlight">
                        <h1>Enveda</h1>
                        <p>At Enveda, we are systematically translating molecules found in medicinal plants into new drugs for challenging diseases.
                            Our platform harnesses nature's complexity with the help of cutting-edge advancements in knowledge graphs, machine learning, and metabolomics.</p>

                    </div>
                </div>
                <div className="row">

                    <div className="prize">
                        <h2>Prize</h2>
                        <p>The most impressive team that chooses either of the following challenges will receive <strong>$10,000 in cash</strong> and have the opportunity to present their work to our team.</p>
                    </div>

                    <div className="challenge">
                        <h2>Challenge 1</h2>
                        <h4>Enhancing MS/MS fragmentation predictions</h4>
                        <p>Mass spectrometry (MS) is a powerful analytical technique used to measure the mass-to-charge ratio (m/z) of ions, enabling the identification and quantification of molecules. In tandem mass spectrometry (MS/MS), molecules are first ionized and then fragmented into smaller ions through a process called collision-induced dissociation (CID). Each fragment corresponds to a specific structural component of the original molecule, producing a characteristic spectrum of peaks that can be used to deduce the molecule's structure.</p>
                        <p>However, one of the main challenges in MS is the vast chemical space and the limited spectral libraries available, which hampers the accurate identification of unknown compounds. Current spectral libraries cover only a small fraction of all possible compounds, creating a significant bottleneck in compound identification.</p>
                        <p>To address this limitation, in silico fragmentation, known as the forward problem, predicts the fragmentation patterns of molecules based on their structures, thereby augmenting spectral libraries with hypothetical spectra and expanding the range of possible identifications. Two modern approaches include:</p>
                        <ul>
                            <li><strong>CFM-ID</strong> uses a probabilistic graphical model to predict the fragmentation patterns of metabolites. It simulates bond cleavage events based on learned fragmentation rules, improving accuracy by considering both the likelihood of fragmentation events and the stability of the resulting fragments.</li>
                            <li><strong>GrAFF-MS by Enveda</strong> leverages graph neural networks (GNNs) to model the spectra of small molecules. It treats mass spectrum prediction as a graph classification problem, encoding molecular structures as graphs. This approach uses a fixed vocabulary of common product ions and neutral losses to predict spectra efficiently without enumerating all possible substructures, balancing computational efficiency with high resolution. GrAFF-MS shows significant improvements in prediction accuracy and speed compared to CFM-ID.</li>
                        </ul>
                        <p>At Enveda, we are committed to advancing the field of in silico fragmentation to gather more labeled data for the backward translation task (spectrum to structure).</p>
                        <p><strong>Your challenge</strong> is to advance in silico fragmentation by building upon the innovative ideas of GrAFF-MS or developing your own solution from scratch. Train on approximately one million curated and cleaned MS/MS spectra, formatted by Enveda.</p>
                    </div>

                    <div className="challenge">
                        <h2>Challenge 2</h2>
                        <h4>Bridging computational models and chemist intuition to advance molecular similarity</h4>
                        <p>Molecular similarity is a foundational concept in chemical informatics and medicinal chemistry, widely used to identify new compounds and predict biological activities. At its core, molecular similarity aims to quantify how alike two molecules are based on their structures, properties, or a combination of both.</p>
                        <p>Current computational methods for assessing molecular similarity primarily rely on representations of molecular structures, such as 2D fingerprints or 3D molecular shapes. These methods typically use molecular descriptors that encode various structural features or physicochemical properties of molecules, followed by a similarity coefficient to calculate a quantitative similarity score.</p>
                        <p>However, these cheminformatics approaches often fail to capture the nuanced perception of similarity that medicinal chemists have, which is more subjective and context-dependent. Unlike computational models, which provide a fixed numerical output, medicinal chemists often evaluate molecular similarity based on a combination of structural motifs, known pharmacophores, and experiential knowledge. This assessment is inherently subjective and influenced by the chemist's personal experience.</p>
                        <p>At Enveda, we are focused on measuring the discrepancy between structural predictions derived from MS/MS spectra and their corresponding ground truth structures. To accurately evaluate the success of these predictions, we must consider more nuanced measures of similarity. To this end, we have curated pairs of molecules annotated with chemists' expert judgments on whether the predictions are suitable for making prioritization decisions. These annotations indicate whether the scaffolds between pairs match, whether they are considered a close or exact match, and include SMILES strings for the critical scaffolds of the molecules.</p>
                        <p><strong>Your challenge</strong> is to develop methods that can accurately classify how closely two molecules resemble each other. Train these models on larger datasets and then fine-tune them using Enveda’s set of 600 molecular pairs curated from COCONUT online, each carefully annotated by five expert chemists.</p>
                    </div>
                </div>
            </div>
            <div className='footer'></div>
        </>
    );
};

export default ChallengePage;
