import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Header.css";
import logo from '../../assets/main/bio-x-ml.svg';
import hamburgerIcon from '../../assets/icons/hamburger-icon.svg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  return (
    <div style={location.pathname === '/' ? {} : { marginBottom: '100px' }}>
      <header className="navbar container" >
        <NavLink id='header-container' onClick={() => setIsActive(false)} to="/"
          style={location.pathname === '/' ? { visibility: 'hidden' } : {}}>
          <img className='header-logo' width='60px' src={logo} alt="Logo" />
        </NavLink>
        <button className="menu-toggle" onClick={() => setIsActive(!isActive)}>
          <img width='40px' src={hamburgerIcon} alt="Menu" />
        </button>
        <nav className="navbar__menu">
          {/* <NavLink onClick={() => setIsActive(false)} to="/events">Events</NavLink> */}
          <NavLink onClick={() => setIsActive(false)} to="/showcase">Showcase</NavLink>
          {/* <NavLink onClick={() => setIsActive(false)} to="/talks">Talks</NavLink> */}
          <NavLink onClick={() => setIsActive(false)} to="/assets">Assets</NavLink>
          <NavLink onClick={() => setIsActive(false)} to="/resources">Resources</NavLink>
          <NavLink onClick={() => setIsActive(false)} to="/About">About</NavLink>
        </nav>
        <div className='button' id='ctab' style={location.pathname === '/' ? { visibility: 'hidden' } : {}}>
          <a href='https://discord.gg/kTEq6t99CK'>Join Discord</a>
        </div>
      </header>
      <nav className={`navbar__menu__collapse ${isActive ? 'active' : ''}`}>
        {/* <button className="menu-toggle" onClick={() => setIsActive(!isActive)}>
          <img width='40px' src={hamburgerIcon} alt="Menu" />
        </button> */}
        {/* <NavLink onClick={() => setIsActive(false)} to="/">Start</NavLink> */}
        {/* <NavLink onClick={() => setIsActive(false)} to="/events">Events</NavLink> */}
        <NavLink onClick={() => setIsActive(false)} to="/showcase">Showcase</NavLink>
        {/* <NavLink onClick={() => setIsActive(false)} to="/talks">Talks</NavLink> */}
        <NavLink onClick={() => setIsActive(false)} to="/assets">Assets</NavLink>
        <NavLink onClick={() => setIsActive(false)} to="/resources">Resources</NavLink>
        <NavLink onClick={() => setIsActive(false)} to="/About">About</NavLink>
      </nav>
    </div>
  );

};



export default Header;

