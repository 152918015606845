import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './components/Home/TempHomePage';
import ProjectsPage from './components/Projects/Projects';
import EventsPage from './components/Events/Events';
import EventPage from './components/Event/Event';
import ProjectPage from './components/Project/Project';
import 'bootstrap/dist/css/bootstrap.min.css';
import AssetsPage from './components/Assets/Assets';
import ResourcesPage from './components/Resources/Resources';
import './App.css';
import AboutPage from './components/About/About';
import Challenge from './components/Challenges/Challenge';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          {<Route path="/" element={<HomePage />} />}
          <Route path="/2024" element={<EventPage />} />
          <Route path="/showcase" element={<ProjectsPage />} />
          <Route path="/assets" element={<AssetsPage />} />
          <Route path="/resources" element={<ResourcesPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/event/:eventId" element={<EventPage />} />
          <Route path="/project/:projectId" element={<ProjectPage />} />
          <Route path="/challenge/enveda-2024" element={<Challenge />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
